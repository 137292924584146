'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (var i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (var _i = 0; _i < ajaxLen; _i++) {
					ajaxArrayList[_i] = $.ajax({
						type: 'GET',
						url: ajaxArray[_i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					if (args[1] === 'success') {
						// includeが1個だけの場合
						var position = $(args[0]).filter(':first').attr('class').split(' ');
						for (var j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(args[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					} else {
						for (var _i2 = 0; _i2 < args.length; _i2++) {
							var result = args[_i2];
							var _position = $(result[0]).filter(':first').attr('class').split(' ');

							for (var _j = 0; _j < _position.length; _j++) {
								if (_position[_j].match(regExp)) {
									_position = _position[_j];
									break;
								}
							}
							$('.' + _position).html(result[0]).children().unwrap();

							console.log('Succeeded to read the include file!:', _position);
						}
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				if (/#/.test(event.currentTarget.href)) {
					var speed = 1200;
					scrollToObject(event, speed);
					return false;
				}
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.currentTarget.href.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 90;
					}
				}

				// $('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
				// lazyloadとの併用のための記述
				$.when($('html, body').animate({
					scrollTop: pos
				}, _speed, 'easeOutExpo')
				// _self.preventDefault(),
				).done(function () {
					if (hash !== '#pagetop') {
						var diff = $(hash).offset().top;
						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							diff -= 60;
						} else {
							diff -= 90;
						}
						if (diff !== pos) {
							$('html, body').animate({
								scrollTop: diff
							}, 10, 'easeOutExpo');
						}
					}
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		lazyload: function lazyload() {
			if ($('.js-lazy-img').length) {
				$('.js-lazy-img').lazyload();
			}
		},
		headerMenu: function headerMenu() {
			var $overlay = $('.header-menu-overlay');
			var scrollTopNow = void 0;

			// <SP/TABLET>ヘッダーメニュー開閉
			if ($('.header-menuBtn').length) {
				var $menu = $('.header-menu');
				$('.header-menuBtn').on('click', function (e) {
					var $this = $('.header-menuBtn');

					$this.toggleClass(a);
					if ($this.hasClass(a)) {
						// 開く
						$menu.addClass(a);
						fixedOnContainer();
					} else {
						// 閉じる
						$menu.removeClass(a);
						fixedOffContainer();
					}
				});
				$overlay.on('click', function (e) {
					$menu.removeClass(a);
					$('.header-menuBtn').removeClass(a);
					fixedOffContainer();
				});
			}

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};

			$('.js-current').each(function (idx, ele) {
				var $href = $(ele).attr('href');
				if (RIKEN.va.pathname.indexOf($href) !== -1) {
					$(ele).addClass(a);
					if (RIKEN.va.pathname !== '/' && RIKEN.va.pathname !== '/index.html') {
						$('.js-current[href="/"]').removeClass(a);
					}
				}
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.modal();
			_this.lazyload();
			_this.headerMenu();
			svg4everybody();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);
$(window).on('load', function () {
	var easeScrollHash = function easeScrollHash() {
		if (location.hash) {
			$('html, body').css('display', 'none');
			setTimeout(function () {
				$('html, body').css('display', 'block');
				var _hash = '#' + location.hash.split('#')[1];
				var pos = $(_hash).offset().top;
				if ($(window).width() < 1024) {
					pos -= 60;
				} else {
					pos -= 90;
				}
				// $('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
				// lazyloadとの併用のための記述
				$.when($('html, body').animate({
					scrollTop: pos
				}, 1200, 'easeOutExpo')
				// _self.preventDefault(),
				).done(function () {
					var diff = $(_hash).offset().top;
					if ($(window).width() < 1024) {
						diff -= 60;
					} else {
						diff -= 90;
					}
					if (diff === pos) {
						$('html, body').animate({
							scrollTop: diff
						}, 10, 'easeOutExpo');
					}
				});
			}, 100);
		}
	};
	easeScrollHash();
});